/* eslint-disable camelcase */
import { graphql, navigate } from 'gatsby';
import React from 'react';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Modi from '../src/components/Modi/Modi';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import PasesTab from '../src/components/Tabs/PasesTab/PasesTab';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import urlResolver from '../src/helpers/urlResolver';
import logo_ap from '../src/images/entradas-logo-large-cap.png';
import logo_fl from '../src/images/entradas-logo-large-fl.png';
import logo_pa from '../src/images/entradas-logo-large-pap.png';
import gold from '../src/images/gold.png';
import platinum from '../src/images/platinum.png';
import logo_club from '../src/images/portaventura-club.png';
import premium from '../src/images/premium.png';
import Layout from '../src/components/Layout/Layout';
import './pase_anual.scss';

export const GatsbyQuery = () => graphql`
  query paseAnual($locale: String!, $pase: String!) {
    allImgPassesHeader(filter: { tag: { eq: $pase } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPasesAnualBreadCrumb {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    # paseAnualRichData {
    #   edges {
    #     nodes {

    #     }
    #   }
    # }
    allPasesAnualPromoOutside(
      filter: { lang: { eq: $locale }, tag: { eq: $pase } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title
          description
          modalTitle
          modalContent
          ctaText
          image_alt
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPasesAnualPromoInside(
      filter: { lang: { eq: $locale }, tag: { eq: $pase } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          title
          characteristics
          ctaText
          image_alt
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const Pases = ({ pageContext, data }) => {
  const { locale } = pageContext;
  const prepareForCta = (locales) => {
    let cta;
    switch (locales) {
      case 'es':
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/322/original/ResumenTipodePase.pdf';
        break;
      case 'ca':
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/323/original/ResumTipusPassis.pdf';
        break;
      case 'en':
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/322/original/ResumenTipodePase.pdf';
        break;
      case 'de':
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/322/original/ResumenTipodePase.pdf';
        break;
      case 'fr':
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/322/original/ResumenTipodePase.pdf';
        break;
      case 'it':
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/322/original/ResumenTipodePase.pdf';
        break;
      default:
        cta =
          'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/011/322/original/ResumenTipodePase.pdf';
    }
    return cta;
  };

  const GeneralInfo = {
    type: 'INFO',
    title: tt('Descubre nuestros diferentes pases para PortAventura World', locale),
    button: {
      cta: prepareForCta(locale),
      size: 'alone',
      color: 'white',
      ctaText: tt('VER LA COMPARATIVA', locale),
    },
    text: [
      {
        title: tt('PRINCIPALES BENEFICIOS DE LOS PASES', locale),
      },
      {
        title: tt('CATEGORÍAS DE PASES', locale),
      },
      {
        title: tt('MODALIDADES DE PAGO Y DESCUENTOS', locale),
      },
    ],
    cards: [
      {
        type: 'GOLD',
        title: tt('PASE GOLD', locale),
        subtitle: `<div><span>${tt(
          'Entrada ilimitada a',
          locale
        )} <strong>PortAventura Park</strong><br /></span></div><p><span>&nbsp;</span></p>`,
        parks: [
          {
            name: 'PAW',
            img: logo_pa,
          },
        ],
        adult: {
          name: tt('Adulto', locale),
          age: tt('11 -59 años', locale),
          price: '163€',
        },
        juniorSenior: {
          name: tt('Júnior/Sénior', locale),
          age: tt('4 - 10 años / +60 años', locale),
          price: '132€',
        },
        reduced: '132€',
        button: [
          {
            // cta: urlResolver.resolve(locale, 'closed'),
            cta: urlResolver.resolve(locale, 'pase_anual', ['pase-gold']),
            size: 'small',
            color: 'white',
            ctaText: tt('VER', locale),
          },
          // {
          //   // cta: CTA_COMPRA_PASE_ANUAL[locale],
          //   // size: 'small',
          //   // color: 'orange',
          //   // ctaText: tt('COMPRAR', locale),
          // },
        ],
      },
      {
        type: 'PREMIUM',
        title: tt('PASE PREMIUM', locale),
        subtitle: `<div><span>${tt(
          'Entrada ilimitada a',
          locale
        )} <strong>PortAventura Park</strong>${tt(
          ' y ',
          locale
        )} <strong>Caribe Aquatic Park</strong></span></div><p><span>&nbsp;</span></p>`,
        parks: [
          {
            name: 'PAW',
            img: logo_pa,
          },
          {
            name: 'AQUA',
            img: logo_ap,
          },
        ],
        adult: {
          name: tt('Adulto', locale),
          age: tt('11 -59 años', locale),
          price: '208€',
        },
        juniorSenior: {
          name: tt('Júnior/Sénior', locale),
          age: tt('4 - 10 años / +60 años', locale),
          price: '177€',
        },
        reduced: '177€',
        button: [
          {
            // cta: urlResolver.resolve(locale, 'closed'),
            cta: urlResolver.resolve(locale, 'pase_anual', ['pase-premium']),
            size: 'small',
            color: 'white',
            ctaText: tt('VER', locale),
          },
          // {
          //   cta: CTA_COMPRA_PASE_ANUAL[locale],
          //   size: 'small',
          //   color: 'orange',
          //   ctaText: tt('COMPRAR', locale),
          // },
        ],
      },
      {
        type: 'PLATINUM',
        title: tt('PASE PLATINUM', locale),
        subtitle: `<div><span>${tt(
          'Entrada ilimitada a',
          locale
        )} <strong>PortAventura Park</strong>, <strong>Caribe Aquatic Park</strong> ${tt(
          ' y ',
          locale
        )} <strong>Ferrari Land</strong></span></div>`,
        parks: [
          {
            name: 'PAW',
            img: logo_pa,
          },
          {
            name: 'AQUA',
            img: logo_ap,
          },
          {
            name: 'FL',
            img: logo_fl,
          },
        ],
        adult: {
          name: tt('Adulto', locale),
          age: tt('11 -59 años', locale),
          price: '260€',
        },
        juniorSenior: {
          name: tt('Júnior/Sénior', locale),
          age: tt('4 - 10 años / +60 años', locale),
          price: '220€',
        },
        reduced: '220€',
        button: [
          {
            // cta: urlResolver.resolve(locale, 'closed'),
            cta: urlResolver.resolve(locale, 'pase_anual', ['pase-platinum']),
            size: 'small',
            color: 'white',
            ctaText: tt('VER', locale),
          },
          // {
          //   cta: CTA_COMPRA_PASE_ANUAL[locale],
          //   size: 'small',
          //   color: 'orange',
          //   ctaText: tt('COMPRAR', locale),
          // },
        ],
      },
    ],
  };

  const clubFunnelLocale = ['ru', 'it', 'de'].includes(locale) ? 'en' : locale;

  const heimdallData = {
    name: tt('Pases anuales Club PortAventura', locale),
    subtitle: tt(
      'Accede de forma ilimitada a nuestros parques PortAventura Park, Caribe Aquatic Park y Ferrari Land, y disfruta de más ventajas dentro y fuera del resort',
      locale
    ),
    image: {
      url: data.allImgPassesHeader.edges[0].node.localImage.childImageSharp.fluid,
      imageUrl:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/103/original/club-portaventura-pases-anuales-mobile.jpg',
    },
  };
  const modiData = {
    Image: logo_club,
    title: tt('¡Selecciona el Pase Anual que mejor se adapte a ti!', locale),
    Text: tt(
      'PortAventura World te ofrece varias tipologías de Pase Anual para adaptarse a las experiencias que quieras vivir durante todo el año: Pase Gold, Pase Premium y Pase Platinum. Y si ya formas parte del CLUB PORTAVENTURA, renueva tu Pase Anual con un 10 % de dto.*',
      locale
    ),
    classContainer: 'image',
    contactText: tt(
      'Telf.: 977 12 90 57 · Horario oficina Club: 10 – Cierre *Consulta el calendario de apertura',
      locale
    ),
    altImage: 'club portaventura',

    //           BORRADO PARA NO POEDER LLEGAR AL FUNNEL DE PASES
    //
    // button1: {
    //   cta: CTA_RENUEVA_PASE_ANUAL[locale],
    //   size: 'alone',
    //   color: 'white',
    //   ctaText: tt('RENUEVA TU PASE ANUAL', locale),
    // },
    button2: {
      cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 1),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRA TU PASE ANUAL', locale),
    },
  };

  const GoldData = {
    type: 'GOLD',
    cardImage: gold,
    title: tt('PASE GOLD', locale),
    subtitle: tt(
      'Aprovecha todas las ventajas de este Pase y diviértete en los mundos de PortAventura Park de forma ilimitada.',
      locale
    ),
    description:
      'Es imprescindible presentar el pase antes de realizar el pago (no se aceptarán reclamaciones posteriores).',
    button: {
      cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 2),
      size: 'alone',
      color: 'white',
      ctaText: tt('RENOVAR AHORA', locale),
    },
    advantage: tt('descubre las ventajas de nuestros pases gold para portaventura world', locale),
    text: [
      {
        title: tt('PASE GOLD', locale),
      },
      {
        title: tt('MODALIDADES DISPONIBLES', locale),
      },
    ],
    renew: tt('Renueva tu Pase Anual con un 10 % de dto. solo hasta el 31/03/2022', locale),
    card: {
      type: 'GOLD',
      title: tt('PASE GOLD', locale),
      subtitle: tt('Entrada ilimitada a PortAventura Park', locale),
      parks: [
        {
          name: 'PAW',
          img: logo_pa,
        },
      ],
      adult: {
        name: tt('Adulto', locale),
        age: tt('11 -59 años', locale),
        price: '163€',
      },
      juniorSenior: {
        name: tt('Júnior/Sénior', locale),
        age: tt('4 - 10 años / +60 años', locale),
        price: '132€',
      },
      reduced: '132€',
      button: [
        {
          cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 1),
          size: 'alone',
          color: 'orange',
          ctaText: tt('COMPRAR PASE GOLD', locale),
        },
      ],
    },
  };
  const PremiumData = {
    type: 'PREMIUM',
    title: tt('PASE PREMIUM', locale),
    cardImage: premium,
    button: {
      cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 2),
      size: 'alone',
      color: 'white',
      ctaText: tt('RENOVAR AHORA', locale),
    },
    advantage: tt(
      'descubre las ventajas de nuestros pases premium para portaventura world',
      locale
    ),
    text: [
      {
        title: tt('PASE PREMIUM', locale),
      },
      {
        title: tt('MODALIDADES DISPONIBLES', locale),
      },
    ],
    renew: tt('Renueva tu Pase Anual con un 10 % de dto. solo hasta el 31/03/2022', locale),
    card: {
      type: 'PREMIUM',
      title: tt('PASE PREMIUM', locale),
      subtitle: tt('Entrada ilimitada a PortAventura Park', locale),
      parks: [
        {
          name: 'PAW',
          img: logo_pa,
        },
        {
          name: 'AQUA',
          img: logo_ap,
        },
      ],
      adult: {
        name: tt('Adulto', locale),
        age: tt('11 -59 años', locale),
        price: '208€',
      },
      juniorSenior: {
        name: tt('Júnior/Sénior', locale),
        age: tt('4 - 10 años / +60 años', locale),
        price: '177€',
      },
      reduced: '177€',
      button: [
        {
          cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 1),
          size: 'alone',
          color: 'orange',
          ctaText: tt('COMPRAR PASE PREMIUM', locale),
        },
      ],
    },
  };
  const PlatinumData = {
    type: 'PLATINUM',
    title: tt('PASE PLATINUM', locale),
    subtitle: tt(
      'Vive la experiencia al máximo con el Pase más completo de PortAventura World: accede a PortAventura Park, Caribe Aquatic Park y Ferrari Land.',
      locale
    ),
    cardImage: platinum,
    button: {
      cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 2),
      size: 'alone',
      color: 'white',
      ctaText: tt('RENOVAR AHORA', locale),
    },
    advantage: tt(
      'descubre las ventajas de nuestros pases premium para portaventura world',
      locale
    ),
    text: [
      {
        title: tt('PASE PLATINUM', locale),
      },
      {
        title: tt('MODALIDADES DISPONIBLES', locale),
      },
    ],
    renew: tt('Renueva tu Pase Anual con un 10 % de dto. solo hasta el 31/03/2022', locale),
    card: {
      type: 'PLATINUM',
      title: tt('PASE PLATINUM', locale),
      subtitle: tt('Entrada ilimitada a PortAventura Park', locale),
      parks: [
        {
          name: 'PAW',
          img: logo_pa,
        },
        {
          name: 'FL',
          img: logo_fl,
        },
        {
          name: 'AQUA',
          img: logo_ap,
        },
      ],
      adult: {
        name: tt('Adulto', locale),
        age: tt('11 -59 años', locale),
        price: '260€',
      },
      juniorSenior: {
        name: tt('Júnior/Sénior', locale),
        age: tt('4 - 10 años / +60 años', locale),
        price: '220€',
      },
      reduced: '220€',
      button: [
        {
          cta: buildFunnelUrl(clubFunnelLocale, 'club-funnel', 1),
          size: 'alone',
          color: 'orange',
          ctaText: tt('COMPRAR PASE PLATINUM', locale),
        },
      ],
    },
  };

  const PrepareforFreya = (src) => ({
    title: tt('BENEFICIOS FUERA DEL PARQUE', locale),
    isSlider: true,
    cards: src.edges
      .map((e) => ({
        image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
        altImage: e.node.image_alt,
        imgsize: 'promoted',
        title: e.node.title,
        description: e.node.description,
        buttons: {
          size: 'alone',
          color: 'white',
          ctaText: e.node.ctaText,
          popup: {
            enable: true,
            titlepop: e.node.modalTitle,
            textpop: e.node.modalContent,
          },
        },
      }))
      .filter((e) => e.image !== undefined),
  });

  const PrepareFreya = (src) => ({
    title: tt('BENEFICIOS DENTRO DEL PARQUE', locale),
    isSlider: true,
    cards: src.edges
      .map((e) => ({
        image: e.node.localImage ? e.node.localImage.childImageSharp.fluid : undefined,
        altImage: e.node.image_alt,
        imgsize: 'promoted',
        title: e.node.title,
        buttons: {
          size: 'alone',
          color: 'white',
          ctaText: e.node.ctaText,
          popup: {
            enable: true,
            textpop: e.node.characteristics,
          },
        },
      }))
      .filter((e) => e.image !== undefined),
  });

  const option = pageContext.options[0];
  const lookup = {
    '': GeneralInfo,
    'pase-gold': GoldData,
    'pase-premium': PremiumData,
    'pase-platinum': PlatinumData,
  };
  const sendData = lookup[option];

  const handleDataChange = (type) => {
    let section = '';
    switch (type) {
      case 'INFO':
        section = '';
        break;
      case 'GOLD':
        section = 'pase-gold';
        break;
      case 'PREMIUM':
        section = 'pase-premium';
        break;
      case 'PLATINUM':
        section = 'pase-platinum';
        break;
      default:
        section = '';
    }

    if (type === sendData.type) return;
    const dest = urlResolver.resolve(locale, 'pase_anual', [section]);
    setTimeout(() => navigate(dest), 1);
  };

  return (
    <Layout layoutFromOld  locale={locale} pageName={pageContext.pageName} options={pageContext.options}>
      <SEO
        title={sendData.title}
        description={tt(
          'Aprovecha al máximo la experiencia PortAventura con tu pase anual. Podrás acceder de forma ilimitada y disfrutar de muchas más ventajas dentro y fuera del parque',
          locale
        )}
        // canonicalLink={pageContext.url}
        pathname={pageContext.url}
        imageRich="https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/103/original/club-portaventura-pases-anuales-mobile.jpg"
        lang={pageContext.locale}
      />
      <div className="is-fullWidth">
        <Heimdall isHardCoded data={heimdallData} />
        <div className="general-index">
          <div className="pases-content">
            <Modi data={modiData} />
            <BlueArrow />
            <NeoAesir
              locale={locale}
              pageName={pageContext.pageName}
              title={heimdallData.name}
              options={pageContext.options}
            />
            {/* <p className="texto-aviso">
              {tt(
                'AVISO IMPORTANTE: Consulta las nuevas condiciones de los Pases de PortAventura World ',
                locale
              )}
              <a
                href={tt(
                  'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/012/803/original/Condiciones_Pases_Entradas_ES.pdf',
                  locale
                )}
                target="_blank"
              >
                {tt('aquí.', locale)}
              </a>
            </p>
            */}
            <div className="texto-aviso">
              <div
                dangerouslySetInnerHTML={{
                  __html: tt(
                    '<p> AVISO IMPORTANTE: Consulta <a href=" https://www.portaventuraworld.com/informacion-importante"> aquí </a> las nuevas condiciones de los pases de PortAventura World. </p>',
                    locale
                  ).toUpperCase(),
                }}
              />
            </div>
            <div className="tab-container">
              <PasesTab
                data={sendData}
                dataBene={PrepareforFreya(data.allPasesAnualPromoOutside)}
                dataBene2={PrepareFreya(data.allPasesAnualPromoInside)}
                locale={locale}
                onChangeData={handleDataChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Pases;
