import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Buri } from '../Buri/Buri';
import './Ull.scss';
import Buttons from '../Buttons/Buttons';
import H4 from '../Titles/TitleH4single/TitleH4single';

export const Ull = (props) => {
  const {
    data: { title, subtitle, cardImage, advantage, text, renew, button, card },
    } = props; //eslint-disable-line

  return (
    <div className="pase-container">
      <h2 className="title-pase"> {title}</h2>
      <div className="subtitle-pase" dangerouslySetInnerHTML={{ __html: subtitle }} />
      <div className="separator">
        <span className="line-separator" />
        <div className="middle-separator">
          <div className="card-separator">
            <img src={cardImage} alt="pases club 2019" />
          </div>
          <span className="equal-separator">=</span>
          <div className="img-parks ">
            {card.parks.map((park) => (
              <div key={park.name} className={`Ull-image ${park.name}`}>
                <img src={park.img} alt="portaventura" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <h3 className="title-pase"> {advantage}</h3>
      <div className="bottom-text-container">
        <div className="left-text-container">
          <H4 title={text[0].title} />
          {card.type === 'GOLD' ? <FormattedMessage id="passG-list1" /> : null}
          {card.type === 'PREMIUM' ? <FormattedMessage id="passPR-list1" /> : null}
          {card.type === 'PLATINUM' ? <FormattedMessage id="passPL-list1" /> : null}

          <ul className="bullet-list">
            <li>
              <FormattedMessage id="pass-list1-option1" />
            </li>
            <li>
              <FormattedMessage id="pass-list1-option2" />
            </li>
            <li>
              <FormattedMessage id="pass-list1-option3" />
            </li>
            <li>
              <FormattedMessage id="pass-list1-option4" />
            </li>
          </ul>
          <H4 title={text[1].title} />
          <FormattedMessage id="passG-list2" />
          <ul className="bullet-list">
            <li>
              <span className="bold">
                <FormattedMessage id="pass-list2-option1-title" />
              </span>
              <FormattedMessage id="passG-list2-option1" />
            </li>
            <li>
              <span className="bold">
                <FormattedMessage id="pass-list2-option6-title" />
              </span>
              <FormattedMessage id="passG-list2-option6" />
              <FormattedMessage id="passG-list2-option7">
                {(link) => (
                  <a href={link} target="_blank" rel="noreferrer">
                    <FormattedHTMLMessage id="aqui-one-two" />{' '}
                  </a>
                )}
              </FormattedMessage>
              <FormattedMessage id="passG-list2-option8" />
            </li>
          </ul>
          <span className="ull-title">
            <FormattedMessage id="pass-list3-title" />
          </span>
          <span className="ull-text">
            <FormattedMessage id="pass-list3" />
          </span>
          <ol className="inside">
            <li>
              <FormattedMessage id="pass-list3-option1" />
            </li>
            <li>
              <FormattedMessage id="pass-list3-option2" />
              <ul className="bullet-list indent">
                <li>
                  <FormattedMessage id="pass-list3-option2-a" />
                </li>
                <li>
                  <FormattedMessage id="pass-list3-option2-b" />
                </li>
              </ul>
            </li>
            {/* <li>
              <FormattedMessage id="pass-list3-option3" />
            </li> */}
          </ol>
          <div className="ull-container-text">
            {/* <div className="ull-container1">
              <ul className="bullet-list">
                <span className="bold">
                  <FormattedMessage id="pass-list4" />
                </span>
                <li>
                  <FormattedMessage id="pass-list4-option1" />
                </li>
                <li>
                  <FormattedMessage id="pass-list4-option2" />
                </li>
                <li>
                  <FormattedMessage id="pass-list4-option3" />
                </li>
              </ul>
            </div> */}
            {/* <div className="ull-container2">
              <ul className="bullet-list">
                <span className="bold">
                  <FormattedMessage id="pass-list5" />
                </span>
                <li>
                  <FormattedMessage id="pass-list5-option1" />
                </li>
                <li>
                  <FormattedMessage id="pass-list5-option2" />
                </li>
                <li>
                  <FormattedMessage id="pass-list5-option3" />
                </li>
                <li>
                  <FormattedMessage id="pass-list5-option4" />
                  <ul className="bullet-list indent">
                    <li>
                      <FormattedMessage id="pass-list5-option4-a" />
                    </li>
                    <li>
                      <FormattedMessage id="pass-list5-option4-b" />
                    </li>
                    <li>
                      <FormattedMessage id="pass-list5-option4-c" />
                    </li>
                  </ul>
                </li>
              </ul>
            </div> */}
          </div>
          <span className="bold">
            <FormattedMessage id="pass-text1-bold" />
          </span>
          <FormattedMessage id="pass-text1" />
        </div>
        <div className="right-text-container">
          <Buri data={card} />
          <span className="renew-pase"> {renew} </span>

          {/* BORRADO PARA NO POEDER LLEGAR AL FUNNEL DE PASES */}

          <Buttons
            link={button.cta}
            size={button.size}
            color={button.color}
            text={button.ctaText}
          />
        </div>
      </div>
    </div>
  );
};
