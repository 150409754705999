import React from 'react';
import { injectIntl } from 'react-intl';
import Buttons from '../Buttons/Buttons';
import Pawlink from '../PawLink/PawLink';
import tt from '../../helpers/translation';
import './Buri.scss';

export const Buri = injectIntl(props => {
  const {
    data: { type, title, subtitle, parks, adult, juniorSenior, reduced, button },
    intl,
  } = props;
  const mapButton = array =>
    array.map(btn => (
      <React.Fragment key={btn.ctaText}>
        <Buttons
          link={btn.cta}
          size={btn.size}
          color={btn.color}
          exception="mini_button"
          text={btn.ctaText}
        />
      </React.Fragment>
    ));

  return (
    <div className="Buri">
      <div className={`Buri-top ${type}`}>
        <div className="img-parks ">
          {parks.map(park => (
            <React.Fragment key={park.name}>
              <div className={`Buri-image ${park.name}`}>
                <img src={park.img} alt="pases anuales portaventura" />
              </div>
            </React.Fragment>
          ))}
        </div>
        {button ? (
          <Pawlink type="named" destination={button.cta} parameters={['']}>
            <div className="Buri-title">
              <h3>{title}</h3>
            </div>
          </Pawlink>
        ) : (
          <div className="Buri-title">
            <h3>{title}</h3>
          </div>
        )}
        <div className="Buri-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>

      <div className="Buri-contain">
        <div className="Buri-prices">
          <div className="adult-prices">
            <div className="name">
              <p>{adult.name}</p>
            </div>
            <div className="age">
              <p>{adult.age}</p>
            </div>
            <div className="price">
              <p>
                <span>{adult.price}</span>
              </p>
            </div>
          </div>
          <div className="juniorSenior-prices">
            <div className="name">
              <p>{juniorSenior.name}</p>
            </div>
            <div className="age">
              <p>{juniorSenior.age}</p>
            </div>
            <div className="price">
              <p>
                <span>{juniorSenior.price}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="price-reduced">
          <p>
            {tt('Persona con discapacidad', intl.locale)}: 33% - 74% <span>{reduced}</span>
          </p>
        </div>
        <div className="level">{button ? mapButton(button) : null}</div>
      </div>
    </div>
  );
});
