import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import H3 from '../../Titles/TitleH3/TitleH3';
import H4 from '../../Titles/TitleH4single/TitleH4single';
import Buttons from '../../Buttons/Buttons';
import { Ull } from '../../Ull/Ull';

import { Buri } from '../../Buri/Buri';
import { Freya } from '../../Freya/Freya';
import tt from '../../../helpers/translation';
import Pawlink from '../../PawLink/PawLink';

import './PasesTab.scss';

function TabContainer(props) {
  const { children } = props; //eslint-disable-line
  return (
    <Typography component="div" style={{ fontFamily: 'Lato' }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    border: 'none',
  },

  default_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 0.8,
    height: '50px',
    width: 'auto',
    padding: '5px',
    marginRight: '3px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },

  active_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 1,
    height: '60px',
    width: 'auto',
    padding: '5px',
    borderRadius: '0 0 10px 10px',
    marginRight: '3px',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#1396D1',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  Wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: '100%',
  },
  labelIcon: {
    minHeight: '0px',
    paddingTop: '0px',
  },
});

class PasesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      openmenu1: false,
    };
  }

  handleClick = (key) => () => {
    this.setState((s) => ({ ...s, [key]: !s[key] }));
  };

  handleChangeTab = (event, value) => {
    this.props.onChangeData(value);
  };

  handleChange = (value) => {
    this.props.onChangeData(value);
  };

  render() {
    const {
      state: { openmenu1, value },
      props: { data, dataBene, dataBene2, classes, locale, intl },
    } = this;
    const mapcard = (array) =>
      array.map((card) => (
        <div>
          <div className="title-disabled-card">
            {/* <p className={`${card.type}`}>
              {tt(
                'ESTE PASE NO SE PODRÁ RENOVAR NI ADQUIRIR DE NUEVO EN LA TEMPORADA 2020',
                locale
              )}
            </p> */}
          </div>
          <Buri key={card.type} data={card} />
        </div>
      ));

    const isMobileTrue = (
      <div className="tab-menu bggrey">
        <ListItem button className="firstlevelpasesmenu" onClick={this.handleClick('openmenu1')}>
          <ListItemText
            className="firstlevelpasesmenu-text"
            inset
            primary={tt(data.type, locale)}
          />
          {openmenu1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openmenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className="secondpaseslevelmenu"
              value="INFO"
              onClick={() => this.handleChange('INFO')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('INFORMACIÓN GENERAL', locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="GOLD"
              onClick={() => this.handleChange('GOLD')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('PASE GOLD', locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="PREMIUM"
              onClick={() => this.handleChange('PREMIUM')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('PASE PREMIUM', locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value="PLATINUM"
              onClick={() => this.handleChange('PLATINUM')}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('PASE PLATINUM', locale)}
              />
            </ListItem>
          </List>
        </Collapse>
      </div>
    );

    const isBrowserTrue = (
      <Tabs
        value={value}
        onChange={this.handleChangeTab}
        centered
        variant="fullWidth"
        className="tab-head"
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={tt('INFORMACIÓN GENERAL', locale)}
          className={data.type === 'INFO' ? classes.active_tabStyle : classes.default_tabStyle} //eslint-disable-line
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />

        <Tab
          label={tt('PASE GOLD', locale)}
          value="GOLD"
          className={
            data.type === 'GOLD'
              ? classes.active_tabStyle + " GOLD" : classes.default_tabStyle + " GOLD"} //eslint-disable-line
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('PASE PREMIUM', locale)}
          value="PREMIUM"
          className={
            data.type === 'PREMIUM'
              ? classes.active_tabStyle + " PREMIUM" : classes.default_tabStyle + " PREMIUM"} //eslint-disable-line
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('PASE PLATINUM', locale)}
          value="PLATINUM"
          className={
            data.type === 'PLATINUM'
              ? classes.active_tabStyle + " PLATINUM" : classes.default_tabStyle + " PLATINUM"} //eslint-disable-line
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
      </Tabs>
    );

    return (
      <div className="tab-container-big">
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher mobile={isMobileTrue} desktop={isBrowserTrue} />
        </MediaServerRender>
        {data.type === 'INFO' && (
          <TabContainer>
            <div className="cont-info-tab">
              <H3 data={data} />
              <div className="cards-info-tab">
                <div className="img-parks ">{mapcard(data.cards)}</div>
              </div>
            </div>
            <div className="cont-info-text">
              <div className="text-info-tab">
                <H4 title={data.text[0].title} />
                <FormattedMessage id="pass-list1" />
                <ul className="bullet-list">
                  <li>
                    <FormattedMessage id="pass-list1-option1" />
                  </li>
                  <li>
                    <FormattedMessage id="pass-list1-option2" />
                  </li>
                  <li>
                    <FormattedMessage id="pass-list1-option3" />
                  </li>
                  <li>
                    <FormattedMessage id="pass-list1-option4" />
                  </li>
                </ul>
                <H4 title={data.text[1].title} />
                <FormattedMessage id="pass-list2" />
                <span className="bold">
                  <FormattedMessage id="pass-list2-title" />
                </span>
                <ul className="bullet-list">
                  <li>
                    <span className="bold">
                      <FormattedMessage id="pass-list2-option1-title" />
                    </span>
                    <FormattedMessage id="pass-list2-option1" />
                  </li>
                  <li>
                    <span className="bold">
                      <FormattedMessage id="pass-list2-option2-title" />
                    </span>
                    <FormattedMessage id="pass-list2-option2" />
                  </li>
                  <li>
                    <span className="bold">
                      <FormattedMessage id="pass-list2-option3-title" />
                    </span>
                    <FormattedMessage id="pass-list2-option3" />
                  </li>
                  <li>
                    <span className="bold">
                      <FormattedMessage id="pass-list2-option4-title" />
                    </span>
                    <FormattedMessage id="pass-list2-option4" />
                  </li>
                  <li>
                    <span className="bold">
                      <FormattedMessage id="pass-list2-option5-title" />
                    </span>
                    <FormattedMessage id="pass-list2-option5" />
                  </li>
                  <li>
                    <span className="bold">
                      <FormattedMessage id="pass-list2-option6-title" />
                    </span>
                    <FormattedMessage id="pass-list2-option6" />
                    <Pawlink
                      type="anchor"
                      destination={tt(
                        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/documents/documents/000/004/492/original/NECESIDADES_ESPECIALES.pdf?1493884676',
                        intl.locale
                      )}
                      target="_blank"
                    >
                      {tt(' aquí', intl.locale)}{' '}
                    </Pawlink>
                    <FormattedMessage id="passG-list2-option8" />
                  </li>
                </ul>
                <H4 title={data.text[2].title} />
                <span className="ull-text">
                  <FormattedMessage id="pass-list3" />
                </span>
                <ol className="inside">
                  <li>
                    <FormattedMessage id="pass-list3-option1" />
                  </li>
                  <li>
                    <FormattedMessage id="pass-list3-option2" />
                    <ul className="bullet-list indent">
                      <li>
                        <FormattedMessage id="pass-list3-option2-a" />
                      </li>
                      <li>
                        <FormattedMessage id="pass-list3-option2-b" />
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <FormattedMessage id="pass-list3-option3" />
                  </li> */}
                </ol>
                <div className="ull-container-text">
                  {/* <div className="ull-container1">
                    <span className="bold">
                      <FormattedMessage id="pass-list4" />
                    </span>
                    <ul className="bullet-list">
                      <li>
                        <FormattedMessage id="pass-list4-option1" />
                      </li>
                      <li>
                        <FormattedMessage id="pass-list4-option2" />
                      </li>
                      <li>
                        <FormattedMessage id="pass-list4-option3" />
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="ull-container2">
                    <span className="bold">
                      <FormattedMessage id="pass-list5" />
                    </span>
                    <ul className="bullet-list">
                      <li>
                        <FormattedMessage id="pass-list5-option1" />
                      </li>
                      <li>
                        <FormattedMessage id="pass-list5-option2" />
                      </li>
                      <li>
                        <FormattedMessage id="pass-list5-option3" />
                      </li>
                      <li>
                        <FormattedMessage id="pass-list5-option4" />
                        <ul className="bullet-list indent">
                          <li>
                            <FormattedMessage id="pass-list5-option4-a" />
                          </li>
                          <li>
                            <FormattedMessage id="pass-list5-option4-b" />
                          </li>
                          <li>
                            <FormattedMessage id="pass-list5-option4-c" />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div> */}
                </div>
                {/* <Buttons
                  link={data.button.cta}
                  size="margin_top"
                  color={data.button.color}
                  text={data.button.ctaText}
                /> */}
              </div>
            </div>
          </TabContainer>
        )}
        {data.type === 'GOLD' && (
          <TabContainer>
            <Ull data={data} />
            <div className="beneficios-in-container">
              <H4 title={dataBene2.title} />
              <Freya data={dataBene2} />
            </div>
            <div className="beneficios-out-container">
              <H4 title={dataBene.title} />
              <Freya data={dataBene} />
            </div>
          </TabContainer>
        )}
        {data.type === 'PREMIUM' && (
          <TabContainer>
            <Ull data={data} />
            <div className="beneficios-in-container">
              <H4 title={dataBene2.title} />
              <Freya data={dataBene2} />
            </div>
            <div className="beneficios-out-container">
              <H4 title={dataBene.title} />
              <Freya data={dataBene} />
            </div>
          </TabContainer>
        )}
        {data.type === 'PLATINUM' && (
          <TabContainer>
            <Ull data={data} />
            <div className="beneficios-in-container">
              <H4 title={dataBene2.title} />
              <Freya data={dataBene2} />
            </div>
            <div className="beneficios-out-container">
              <H4 title={dataBene.title} />
              <Freya data={dataBene} />
            </div>
          </TabContainer>
        )}
      </div>
    );
  }
}

PasesTab.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default injectIntl(withStyles(styles)(PasesTab));
